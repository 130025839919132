import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  MultipleComponentIterator,
  SlideReveal,
  FullImageCard,
  InternalLink,
  Conditional,
  LocationName,
  FishermanIcon,
} from "@bluefin/components";
import { Grid, Header, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import { createLocationSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class LocationsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
      allFishermanBusinessFile,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"locations-page"} hero={null}>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"locations-content-container"}
          >
            <Grid.Column width={16}>
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "locations_header",
                  defaultValue: "Locations",
                })}
              />
              <MarkdownContent
                className={"locations-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "locations_description",
                })}
              />
              <Card.Group itemsPerRow={3}>
                <MultipleComponentIterator
                  iterator={allFishermanBusinessLocation.nodes}
                  components={[
                    {
                      component: (
                        <SlideReveal
                          className={"ui card"}
                          direction={"up"}
                          triggerOnce={true}
                          fade={true}
                        />
                      ),
                      propMap: { key: "street" },
                      children: [
                        {
                          component: (
                            <FullImageCard
                              as={InternalLink}
                              link={true}
                              event={{
                                category: "Locations",
                                action: "View Location Details",
                              }}
                            />
                          ),
                          propMap: {
                            "event.label": "name",
                            to: createLocationSlug,
                            image: withNamedArgs({
                              func: getBusinessFiles,
                              args: {
                                businessFiles: allFishermanBusinessFile.nodes,
                                numToSelect: 1,
                                filesField: "featuredImage",
                              },
                            }),
                          },
                          children: [
                            {
                              component: <Header />,
                              children: [
                                {
                                  component: <Conditional />,
                                  children: [
                                    {
                                      component: <Conditional.Condition />,
                                      propMap: {
                                        condition: "name",
                                        children: "name",
                                      },
                                    },
                                    {
                                      component: <Conditional.Condition />,
                                      propMap: {
                                        condition: "city",
                                        children: "city",
                                      },
                                    },
                                    {
                                      component: <Conditional.Default />,
                                      propMap: { children: "street" },
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              component: <LocationName />,
                              propMap: { "address.street": "street" },
                            },
                            {
                              component: <LocationName />,
                              propMap: {
                                "address.city": "city",
                                "address.state": "state",
                                "address.zip": "zipCode",
                              },
                            },
                            {
                              component: (
                                <FishermanIcon
                                  iconName={"arrow-right"}
                                  className={"location-arrow-right"}
                                />
                              ),
                            },
                          ],
                        },
                      ],
                    },
                  ]}
                />
              </Card.Group>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Locations" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        state
        zipCode
        name
        featuredImage
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
